import request from '@/http.js'

/******************************商品管理******************************/
/**
 * @description 商城管理 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
export function shopListApi (param) {
    return request.post(
        '/admin.product.product/getList',
        param
    )
}

/**
 * @description 商品详情
 * @param {Object} param data {Object} 传值参数
 */
 export function shopReadApi (param) {
    return request.post(
        '/admin.product.product/read',
        param
    )
}

/**
 * @description 添加商品所有下拉数据
 * @param {Object} param data {Object} 传值参数
 */
 export function shopAddSelectApi (param) {
    return request.post(
        '/admin.product.product/add',
        param
    )
}

/**
 * @description 生成组合商品
 * @param {Object} param data {Object} 传值参数
 */
 export function shopConfirmApi (param) {
    return request.post(
        '/admin.product.product/formatAttr',
        param
    )
}

/**
 * @description 添加商品
 * @param {Object} param data {Object} 传值参数
 */
 export function shopSubmitApi (param) {
    return request.post(
        '/admin.product.product/edit',
        param
    )
}

/**
 * @description 删除商品
 * @param {Object} param data {Object} 传值参数
 */
 export function shopDelApi (param) {
    return request.post(
        '/admin.product.product/delete',
        param
    )
}

/**
 * @description 读取商品详情数据
 * @param {Object} param data {Object} 传值参数
 */
 export function shopReadDetailApi (param) {
    return request.post(
        '/admin.product.description/read',
        param
    )
}

/**
 * @description 保存商品详情
 * @param {Object} param data {Object} 传值参数
 */
 export function shopSaveDetailApi (param) {
    return request.post(
        '/admin.product.description/edit',
        param
    )
}

/**
 * @description 商品排序
 * @param {Object} param data {Object} 传值参数
 */
 export function shopListSortlApi (param) {
    return request.post(
        '/admin.product.product/setOrder',
        param
    )
}

/**
 * @description 活动设置
 * @param {Object} param data {Object} 传值参数
 */
 export function shopActiveSetApi (param) {
    return request.post(
        '/admin.product.product/editOther',
        param
    )
}

/**
 * @description 批量上下架
 * @param {Object} param data {Object} 传值参数
 */
 export function shopShowAllApi (param) {
    return request.post(
        '/admin.product.product/showAll',
        param
    )
}

/**
 * @description 单独商品上下架
 * @param {Object} param data {Object} 传值参数
 */
 export function shopShowApi (param) {
    return request.post(
        '/admin.product.product/show',
        param
    )
}

/*****************************商品秒杀*****************************/
/**
 * @description 秒杀列表
 * @param {Object} param data {Object} 传值参数
 */
 export function shopSkillListApi (param) {
    return request.post(
        '/admin.product.seckill/getList',
        param
    )
}

/**
 * @description 秒杀删除
 * @param {Object} param data {Object} 传值参数
 */
 export function shopSkillDeleteApi (param) {
    return request.post(
        '/admin.product.seckill/delete',
        param
    )
}

/*****************************商品拼团*****************************/
/**
 * @description 拼团列表
 * @param {Object} param data {Object} 传值参数
 */
 export function shopGroupListApi (param) {
    return request.post(
        '/admin.product.group/getList',
        param
    )
}

/**
 * @description 拼团删除
 * @param {Object} param data {Object} 传值参数
 */
 export function shopGroupDeleteApi (param) {
    return request.post(
        '/admin.product.group/delete',
        param
    )
}

/*****************************商品分类*****************************/

 /* @description 商品分类列表
 * @param {Object} param data {Object} 传值参数
 */
 export function shopTypeListApi (param) {
    return request.post(
        '/admin.product.category/getList',
        param
    )
}

/* @description 编辑商品分类
 * @param {Object} param data {Object} 传值参数
 */
export function shopTypeEditApi (param) {
    return request.post(
        '/admin.product.category/edit',
        param
    )
}

/* @description 删除商品分类
 * @param {Object} param data {Object} 传值参数
 */
export function shopTypeDelApi (param) {
    return request.post(
        '/admin.product.category/delete',
        param
    )
}

/*****************************商品场景*****************************/

 /* @description 商品场景列表
 * @param {Object} param data {Object} 传值参数
 */
 export function shopSceneListApi (param) {
    return request.post(
        '/admin.product.series/getList',
        param
    )
}

/* @description 编辑商品场景
 * @param {Object} param data {Object} 传值参数
 */
export function shopSceneEditApi (param) {
    return request.post(
        '/admin.product.series/edit',
        param
    )
}

/* @description 删除商品场景
 * @param {Object} param data {Object} 传值参数
 */
export function shopSceneDelApi (param) {
    return request.post(
        '/admin.product.series/delete',
        param
    )
}

/*****************************用户标签*****************************/

/* @description 用户标签 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
export function shopTagsListApi (param) {
    return request.post(
        '/admin.userLabel/getList',
        param
    )
}

/* @description 用户标签 -- 删除
 * @param {Object} param data {Object} 传值参数
 */
export function shopTagsDeleteApi (param) {
    return request.post(
        '/admin.userLabel/delete',
        param
    )
}

/* @description 用户标签 -- 编辑
 * @param {Object} param data {Object} 传值参数
 */
export function shopTagsEditApi (param) {
    return request.post(
        '/admin.userLabel/edit',
        param
    )
}

// 新增预售列表

export function ysalesListApi (param) {
    return request.post(
        '/admin.productPre/index',
        param
    )
}

//预售商品删除
 export function ysalesDelApi (param) {
    return request.post(
        '/admin.productPre/delete',
        param
    )
}

// 预售活动回显
 export function ysalesshow(param) {
    return request.post(
        '/admin.product/actGet',
        param
    )
}
/************************************新增************************************/

 export function yearsApi(param) {
    return request.post(
        '/admin.systemYears/getYears',
        param
    )
}



export function cateListApi(param) {
    return request.post(
        '/admin.product.categoryMember/getList',
        param
    )
}

export function catereadApi(param) {
	return request.post(
		'/admin.product.categoryMember/read',
		 param
	)
}

export function cateListDelApi(param) {
	return request.post(
		'/admin.product.categoryMember/delete',
		 param
	)
}

export function cateListeditApi(param) {
	return request.post(
		'/admin.product.categoryMember/edit',
		 param
	)
}




export function cateShowApi(param) {
	return request.post(
		'/admin.product.categoryMember/show',
		 param
	)
}


export function cateShowAllApi(param) {
	return request.post(
		'/admin.product.categoryMember/showAll',
		 param
	)
}


export function editIntegralApi(param) {
	return request.post(
		'/admin.product.product/editIntegral',
		 param
	)
}



export function cateAddSelectApi (param) {
    return request.post(
        '/admin.product.categoryMember/getCateList',
        param
    )
}

export function getSaleList (param) {
    return request.post(
        '/admin.admin/indexSale',
        param
    )
}