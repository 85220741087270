<!--商品列表-->
<template>
    <div class="mainBox">
        <!--场景搜索-->
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-jc-sb flex-wrap">
                <label>场景：</label>
                <el-select v-model="select.cate_id" placeholder="选择商品分类" filterable clearable class="selectWidth" @change="curr=1;getList()">
                    <el-option v-for="item in selectData['商品分类']" :label="item.cate_name" :value="item.id" :key="item.id"></el-option>
                </el-select>
                <el-input placeholder="搜索商品名称" v-model="select.search" size="small" style="width:300px;margin-right:auto" @change="curr=1;getList()" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" style="background：#fff" @click="curr=1;getList()"></el-button>
                </el-input>
                <el-button type="primary" block size="small" @click="edit()">添加商品</el-button>
                <el-button-group style="margin:0 5px;" v-if="isSwitch">
                    <el-button type="success" size="small" @click="setupSwitch(1)" icon="el-icon-top">上架</el-button>
                    <el-button type="success" size="small" @click="setupSwitch(0)" icon="el-icon-bottom">下架</el-button>
                </el-button-group>
                <!-- <el-button type="warning" block size="small" @click="exportFun()">导出</el-button> -->
            </div>
        </div>

        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px )">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' @selection-change="handleSelectionChange" height="calc( 100% - 41px )">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="id" label="商品ID" width="80" show-overflow-tooltip></el-table-column>
                <el-table-column prop="name" label="商品名称" show-overflow-tooltip></el-table-column>
                <el-table-column label="商品图片" width="100" show-overflow-tooltip>
                    <template slot-scope="scope">
                       <el-image class="tableImage" :src="domain + scope.row.image" fit="cover" :preview-src-list="[domain + scope.row.image]"></el-image>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="cate_name" label="商品分类" width="150" show-overflow-tooltip></el-table-column> -->
                <el-table-column prop="series_name" label="场景标签" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="price" label="商品价格" width="130" show-overflow-tooltip></el-table-column>
				<el-table-column prop="integral" label="消耗积分" width="130" show-overflow-tooltip></el-table-column>
                <!-- <el-table-column prop="stock" label="库存" show-overflow-tooltip></el-table-column> -->
                <!-- <el-table-column prop="actual_sales" label="销量" width="100" show-overflow-tooltip></el-table-column> -->
                <!-- <el-table-column prop="sales" label="拟定销量" width="100" show-overflow-tooltip></el-table-column> -->
                <!-- <el-table-column prop="sort" label="排序" width="100">
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.sort" style="width:90px" controls-position="right" @change="sortHandleChange(scope.row.sort,scope.row.id)" :min="0" size="mini"></el-input-number>
                    </template>
                </el-table-column> -->
                <el-table-column prop="state_name" label="上架" width="80" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                       <el-switch v-model="scope.row.is_show" :active-value="1"  :inactive-value="0" @change="setupSwitch(scope.row,'show')"></el-switch>
                    </template>
                </el-table-column>
                
                <el-table-column label="操作" align="center" fixed="right" width="320">
                    <template slot-scope="scope">
                        <!-- <el-button type="warning" plain size="mini" @click="setupActive(scope.row)">活动设置</el-button> -->
                        <el-button type="success" plain size="mini" @click="edit(scope.row,'show')">查看</el-button>
                        <el-button type="primary" plain size="mini" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="danger" plain size="mini" @click="deletes(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>

        <!--活动设置-->
        <el-dialog title="活动设置" :visible.sync="dialogFormVisible" :modal-append-to-body="false"  :close-on-click-modal="false" width="600px">
            <el-form ref="formAuth"  :model="formData"  size="small" label-position="top" class="alertForm">
				<!-- <el-form-item label="" prop="cate_name" style="width:100%">
				    <el-checkbox v-model="formData.is_best">精品推荐</el-checkbox>
				</el-form-item> -->
				<el-form-item style="width:100%">
				    <el-checkbox v-model="formData.is_pre">设置预售</el-checkbox>
				    <el-date-picker v-if="formData.is_pre" v-model="formData.pay_first_time" style="width:calc(100% - 100px);margin-left:10px" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
				    </el-date-picker>	
					<div class="flex flex-ai-c" v-if="formData.is_pre"  style="width:68%;margin: 0 auto;margin-top: 10px;">
						<!-- <el-form-item label="限购数量" style="width:50%;margin-right: 20px;">
							<el-input  v-model="formData.num_limit" autocomplete="off"></el-input>
						</el-form-item> -->
						<el-form-item label="限制等级" style="width:50%">
							<el-select v-model="formData.vip_limit" size="small" style="margin-right:5px" clearable placeholder="限制等级">
							    <el-option v-for="item in memberlist" :key="item.id" :label="item.name" :value="item.id">
							    </el-option>
							</el-select>
						</el-form-item>
					</div>	
					
				</el-form-item>
                
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="submit()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import {shopListApi,shopTypeListApi,shopDelApi,shopListSortlApi,shopActiveSetApi,shopShowAllApi,shopShowApi,ysalesshow}  from '@/api/store.js';
	import {memberLevelListApi}  from '@/api/config.js';
    export default {
        data() {
            return {
                select:{},
                selectData:[],//下拉选择列表
                list:[], //商品列表
                previewList:[], //图片预览列表

                title:'添加商品',
                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数
                switchArrayId:'',

                dialogFormVisible:false,//活动设置弹窗
                isSwitch:false, //是否展示上下架
				memberlist:{},
				
				formData:{
				  num_limit:0,
				  vip_limit:0
				},
				series_id:39
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init: function(){
				this.membergetList()
                this.getList();
                this.getTypeList();
				// 会员等级
            },
            //商品列表
            getList: function(){
                shopListApi({
                    curr:this.curr,
                    row:this.row,
					series_id:this.series_id,
                    ...this.select
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                    // response.list.forEach(item => {
                    //     this.previewList.push(this.domain + item.image);
                    // });
                })
            },
            //获取商品分类
            getTypeList: function(){
                shopTypeListApi({
                    curr:1,
                    row:99999
                }).then(response=>{
                    this.selectData['商品分类'] = response;
					console.log("this.selectData['商品分类']",this.selectData['商品分类'])	
                })
            },
            /**
             * 活动设置
            */
            setupActive: function(row){
                this.dialogFormVisible = true;
                this.formData = row;
                this.$set(this.formData,'seckill',false);
                this.$set(this.formData,'group',false);
                this.$set(this.formData,'is_best',false)
				ysalesshow({
					 id:row.id
				}).then(response=>{
					if(response.pre!=null){
						this.$set(this.formData,'is_pre',true)				
						this.$set(this.formData,'vip_limit',response.pre.vip_limit)
						this.$set(this.formData,'pay_first_time',[response.pre.ext_pay_first_from_time,response.pre.ext_pay_first_to_time])
					}else{
						this.$set(this.formData,'is_pre',false)
					}
				})
				
            },

            //编辑商品
            edit: function(param = {},_state){
                //判断添加商品or编辑商品
                if(typeof param.id == 'undefined'){
                    this.title = '添加商品';
                    this.isShow = false;
                }else if(typeof param.id && _state){
                    this.title = '商品详情';
                    this.isShow = true;
                }else {
                    this.title = '编辑商品';
                    this.isShow = false;
                }
                //跳转至添加or编辑页面
                this.$router.push({path:'/PointAdd',query:{id:param.id,title:this.title,isShow:JSON.stringify(this.isShow)}});
            },

            //删除商品
            deletes:function(id){
                this.$confirm('此操作将永久删除该文件, 是否继续', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    shopDelApi({
                        id:id
                    }).then(()=>{
                        this.$message({
                            type: 'success',
                            message: '已删除!'
                        });
                        this.getList();
                    })
                }).catch(()=>{})
            },

            //设置上下架
            setupSwitch: function(row,state) {
                let switchApi = state ? shopShowApi : shopShowAllApi; //接口批量or单独
                let rows = state ? row.is_show  : row; //上架、下架
                let switchId = state ? row.id : this.switchArrayId; //选择上下架id
                switchApi({
                    id:switchId,
                    is_show:rows
                }).then(()=>{
                    this.$message({
                        type: 'success',
                        message: '设置成功!'
                    });
                    this.getList();
                })
            },

            //批量设置上架
            handleSelectionChange: function(val) {
                this.isSwitch = val.length != 0 ? true : false;
                this.switchArrayId =  val.map(item => item.id).toString()   
            },

            //商品排序
            sortHandleChange: function(data, id){
                shopListSortlApi({
                   id:id,
                   sort:data
                }).then(()=>{
                    this.$message({
                        type: 'success',
                        message: '排序设置成功!'
                    });
                })
            },

            //活动设置提交
            submit: function(){
                console.log(this.formData.best)
                if(!this.formData.seckill && !this.formData.group && !this.formData.is_best && !this.formData.is_pre){
                    this.$message({
                        type: 'error',
                        message: '请选择活动设置!'
                    });
                    return;
                 }
                if(this.formData.seckill == true && (!this.formData.seckill_time || this.formData.seckill_time.length == 0)){
                    this.$message({
                        type: 'error',
                        message: '请设置秒杀时间!'
                    });
                    return;
                 }
                if(this.formData.group == true && (!this.formData.group_time || this.formData.group_time.length == 0)){
                    this.$message({
                        type: 'error',
                        message: '请设置拼团时间!'
                    });
                    return;
                }
				if(this.formData.is_pre == true && (!this.formData.pay_first_time || this.formData.pay_first_time.length == 0)){
				    this.$message({
				        type: 'error',
				        message: '请设置预售时间!'
				    });
				    return;	
					
				}
				console.log("this.formData",this.formData)
				// console.log("this.formData.num_limit",this.formData.num_limit)
				// if(this.formData.is_pre == true && !this.formData.num_limit){
				//     this.$message({
				//         type: 'error',
				//         message: '请设置预售数量!'
				//     });
				//     return;
				// }
				if(this.formData.is_pre == true && !this.formData.vip_limit){
				    this.$message({
				        type: 'error',
				        message: '请设置预售等级!'
				    });
				    return;
				}			

                shopActiveSetApi({
                    id:this.formData.id,
                    is_seckill:this.formData.seckill == true ? 1 : 0,
                    is_group:this.formData.group == true ? 1 : 0,
                    seckill_time:this.formData.seckill_time,
                    group_time:this.formData.group_time,
                    is_best:this.formData.best == true ? 1 : 0,
					is_pre:this.formData.is_pre == true ? 1 : 0,
					pay_first_time:this.formData.pay_first_time,
					// num_limit:this.formData.num_limit,
					vip_limit:this.formData.vip_limit					
                }).then(()=>{
                    this.dialogFormVisible = false;
                    this.getList();
                    this.$message({
                        type: 'success',
                        message: '活动设置成功!'
                    });
                })
            },
            //导出
            exportFun:function(){
                this.$confirm(`确定导出商品吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    let url =`/exportExcel/product?param={"cate_id":"${this.select.cate_id}","search":"${this.select.search}"}`;
                    location.href = this.domain + url;
                    loading.close();
                }).catch(()=>{
                    this.$message('已取消当前操作')
                })
            },
            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
			
			
			
			//会员列表
			membergetList: function(){
			    memberLevelListApi({
			    }).then(response=>{
			        this.memberlist = response.list;
			    })
			},
        },
    }
</script>